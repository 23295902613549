import "./index.css";
import Lander from "../../components/home/lander";
import { isMobile, osName } from "react-device-detect";
import { useEffect } from "react";

const Download = () => {
  useEffect(() => {
    if (osName === "iOS" || osName === "Mac OS") {
      window.open(
        "https://apps.apple.com/gb/app/huue-graphics/id1584111866",
        "_self"
      );
    } else {
      window.open(
        "https://play.google.com/store/apps/details?id=io.huue.huue",
        "_self"
      );
    }
  }, []);
  return (
    <div className="home">
      <Lander isMobile={isMobile} os={osName} />
    </div>
  );
};

export default Download;
