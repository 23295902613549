import "./index.css";
import instagram from "../../../assets/icons/instagram.svg";
import linkedin from "../../../assets/icons/linkedIn.svg";
// import youtube from "../../../assets/icons/youtube.svg";
import { Link } from "react-router-dom";

const index = () => {
  return (
    <div className="footer">
      <div className="footerInner">
        <div className="socialLogos">
          <img
            onClick={() => window.open("https://www.instagram.com/huue.io/")}
            src={instagram}
            className="instagramLogo"
            alt="instagram"
          />
          <img
            onClick={() =>
              window.open("https://www.linkedin.com/company/huueio/")
            }
            src={linkedin}
            className="linkedinLogo"
            alt="linkedIn"
          />
          {/* <img src={youtube} className='youtubeLogo' /> */}
        </div>
        <div className="policies">
          <Link
            to="/privacy"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <p>privacy policy</p>
          </Link>
          <Link
            to="/tos"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <p>terms of service</p>
          </Link>
          <Link
            to="/aup"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <p>acceptable use</p>
          </Link>

          <Link
            to="/cookies"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <p>cookies</p>
          </Link>
        </div>
        <div className="copyright">
          <h3>©2022 HUUE LTD. All rights reserved.</h3>
        </div>
      </div>
    </div>
  );
};

export default index;
