import android from "../../../assets/icons/android.svg";
import apple from "../../../assets/icons/apple.svg";
import "./index.css";
import Button from "../../general/button";
import Mockup from "../../../assets/images/mockup.png";
interface Props {
  isMobile: boolean;
  os: string;
}

const Lander = ({ isMobile, os }: Props) => {
  return (
    <div className="lander">
      <div className="cta">
        <h1>
          design social media graphics that <em>change the world!</em> 🎨🌍
        </h1>
        <div className="download">
          <Button
            title="download app"
            type="color"
            textColor="black"
            onClick={() => {
              if (os === "iOS" || os === "Mac OS") {
                // window.open("https://testflight.apple.com/join/ywWAVKob");
                window.open(
                  "https://apps.apple.com/gb/app/huue-graphics/id1584111866"
                );
              } else {
                window.open(
                  "https://play.google.com/store/apps/details?id=io.huue.huue"
                );
              }
            }}
          />
          <div className="osLogos">
            <img
              src={android}
              className="android"
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=io.huue.huue"
                );
              }}
              alt="android"
            />
            <img
              src={apple}
              className="apple"
              onClick={() => {
                window.open(
                  "https://apps.apple.com/gb/app/huue-graphics/id1584111866"
                );
              }}
              alt="iOS"
            />
          </div>
        </div>
      </div>
      <div className="hero">
        <img src={Mockup} alt="phone mockup" />
      </div>
    </div>
  );
};

export default Lander;
