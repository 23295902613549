import "./index.css";
import Lander from "../../components/home/lander";
import { isMobile, osName } from "react-device-detect";
import Product from "../../components/home/Product";
import product1 from "../../assets/images/product/Images-01.png";
import product2 from "../../assets/images/product/Images-02.png";
import product3 from "../../assets/images/product/Images-03.png";
import product4 from "../../assets/images/product/Images-04.png";
import product5 from "../../assets/images/product/Images-05.png";

const index = () => {
  return (
    <div className="home">
      <Lander isMobile={isMobile} os={osName} />

      <h2 className="subtitle">app features</h2>
      <Product
        text={
          <>
            create social media posts - start from a{" "}
            <em>professionally designed</em> template or a blank canvas
          </>
        }
        image={product1}
      />
      <Product
        text={
          <>
            create <em>seamlessly scrolling carousel posts</em> for Instagram
          </>
        }
        image={product2}
        backgroundColor={"#e6faff"}
        swapped
      />
      <Product
        text={
          <>
            join the huue community and <em>remix</em> other peoples' designs
          </>
        }
        image={product5}
        backgroundColor={"#fff8fc"}
      />
      <Product
        text={
          <>
            utilise powerful <em>text and font</em> customisation options
          </>
        }
        image={product3}
        backgroundColor={"#f7f3ff"}
        swapped
      />
      <Product
        text={
          <>
            use <em>3 million + stock photos</em> in your designs
          </>
        }
        image={product4}
        backgroundColor={"#fffaf3"}
      />
    </div>
  );
};

export default index;
