import "./index.css";
import instagram from "../../../assets/icons/instagram.svg";
import linkedin from "../../../assets/icons/linkedIn.svg";
import youtube from "../../../assets/icons/youtube.svg";
import { Link, useLocation } from "react-router-dom";

interface Props {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
}

const Index = ({ menuOpen, setMenuOpen }: Props) => {
  const location = useLocation();

  return (
    <div>
      {menuOpen ? (
        <div className="mobileNav">
          <div className="mobileNavInner">
            <div className="menuTitles">
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setMenuOpen(!setMenuOpen);
                  document.body.style.overflow = "auto";
                }}
              >
                <p className={location.pathname === "/" ? "activePath" : ""}>
                  home
                </p>
              </Link>
              <Link
                to="/contact"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setMenuOpen(!setMenuOpen);
                  document.body.style.overflow = "auto";
                }}
              >
                <p
                  className={
                    location.pathname === "/contact" ? "activePath" : ""
                  }
                >
                  contact
                </p>
              </Link>
              <Link
                to="/preorder"
                onClick={() => {
                  window.scrollTo(0, 0);
                  setMenuOpen(!setMenuOpen);
                  document.body.style.overflow = "auto";
                }}
              >
                <p
                  className={
                    location.pathname === "/preorder" ? "activePath" : ""
                  }
                >
                  preorder
                </p>
              </Link>
            </div>
            <div className="socialLogos">
              <img
                onClick={() =>
                  window.open("https://www.instagram.com/huue.io/")
                }
                alt="instagram"
                src={instagram}
                className="instagramLogo"
              />
              <img
                onClick={() =>
                  window.open("https://www.linkedin.com/company/huueio/")
                }
                alt="linkedIn"
                src={linkedin}
                className="linkedinLogo"
              />
              <img src={youtube} className="youtubeLogo" alt="youtube" />
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Index;
