//#region funky stuff
import { useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//#endregion
//#region css
import "./App.css";
import "./styles/variables.css";
import "./assets/css/fonts.css";
//#endregion
//#region pages
import Home from "../src/pages/home";
// import Contact from "../src/pages/contact";
import Download from "../src/pages/download";
import Cookies from "../src/pages/legal/cookies";
import Tos from "../src/pages/legal/tos";
import Privacy from "../src/pages/legal/privacy";
import Aup from "../src/pages/legal/aup";
// import Preorder from "../src/pages/preorder";
//#endregion

//#region components
import Header from "./components/general/header";
import Footer from "./components/general/footer";
import MobileNav from "./components/general/mobileNav";
//#endregion

function App() {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <Router>
      <div className="app">
        <Header menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <MobileNav menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        <div className="page-wrapper">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/download" component={Download} />
            {/* <Route exact path='/download' component={Download} /> */}
            {/* <Route exact path='/contact' component={Contact} /> */}
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/cookies" component={Cookies} />
            <Route exact path="/tos" component={Tos} />
            <Route exact path="/aup" component={Aup} />
            {/* <Route exact path='/preorder' component={Preorder} /> */}
          </Switch>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
