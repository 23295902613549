import "./index.css";

interface Props {
  title: string;
  type?: "default" | "color";
  onClick: () => void;
  color?: string;
  textColor?: string;
  enabled?: boolean;
}

const index = ({
  title,
  type = "default",
  onClick,
  color = "#000000",
  textColor = "#ffffff",
  enabled = true,
}: Props) => {
  return (
    <div
      className={"button " + (type === "color" ? "gradient-background" : "")}
      onClick={() => {
        // if (enabled)
        onClick();
      }}
      style={{ color: textColor }}
    >
      {title}
    </div>
  );
};

export default index;
