import "./index.css";
import logo from "../../../assets/images/logoB.png";
import { Link } from "react-router-dom";
// import React, { useState } from "react";

interface Props {
  menuOpen: boolean;
  setMenuOpen: (menuOpen: boolean) => void;
}

const Index = ({ menuOpen, setMenuOpen }: Props) => {
  return (
    <div className="header">
      <div className="headerInner">
        <Link
          to="/"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        >
          <img src={logo} alt="logo" />
        </Link>
        <p>poc+queer owned! ✊🏾🏳️‍🌈</p>
      </div>
    </div>
  );
};

export default Index;
