import "./Product.css";

interface Props {
  text: React.ReactNode;
  image: string;
  backgroundColor?: string;
  swapped?: boolean;
  style?: React.CSSProperties;
}

const Product = ({
  text,
  image,
  backgroundColor = "white",
  style,
  swapped = false,
}: Props) => {
  return (
    <div
      className="product"
      style={{ flexDirection: swapped ? "row-reverse" : "row", ...style }}
    >
      <img src={image} style={{ backgroundColor }} alt="product" />
      <div className="spacer" />
      <h2>{text}</h2>
    </div>
  );
};

export default Product;
